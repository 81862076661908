import React, { useRef } from "react"
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Parser from "html-react-parser"
import Flip from "react-reveal/Flip"

import Section from "../elements/Section"
import globalVariables from "../globalVariables"
import Title, { TitleH2 } from "../elements/Title"
import { NextArrow, PrevArrow } from "../elements/ArrowCarousel"

const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: ${globalVariables.minTablet}) {
    flex-direction: row;
    align-items: ${props => (props.align ? props.align : "center")};
    flex-wrap: wrap;
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    flex-wrap: nowrap;
  }
`

const Item = styled.div`
  flex: 1 1 100%;
  background: ${props => (props.color ? props.theme[props.color] : null)};
  padding: 40px 20px;
  @media (max-width: ${globalVariables.maxMobile}) {
    .react-reveal {
      animation: none !important;
      opacity: 1 !important;
    }
  }
  @media (min-width: ${globalVariables.minTablet}) {
    padding: ${props => (props.padding ? props.padding : null)};
    min-height: ${props => (props.height ? props.height : null)};
    &.avantages-items {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    flex: ${props => props.flex};
  }
`

const Text = styled.div`
  margin-top: 60px;
  margin-bottom: 0;
`

const WrapperSlider = styled.div`
  @media (max-width: ${globalVariables.maxMobile}) {
    padding: 0 20px;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    .slick-track {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .slick-slide {
        flex: 0 0 33%;
      }
    }
  }
`

const Avantage = styled.div`
  text-align: center;
  margin: 1rem 0;
  img {
    margin: 0 auto 20px;
    height: 65px;
  }
`

const TextAvantage = styled.p`
  padding: 20px 0;
  max-width: 220px;
  margin: auto;
  @media (min-width: ${globalVariables.minTablet}) {
    padding: 10px 0 25px;
  }
`

const Avantages = ({ title, text, items }) => {
  const sliderRef = useRef()

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: items.length,
    slidesToScroll: 0,
    initialSlide: 0,
    nextArrow: <NextArrow color />,
    prevArrow: <PrevArrow color />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          autoplaySpeed: 2000,
          autoplay: true,
          afterChange: slick => {
            if (slick === items.length - 1) {
              setTimeout(function() {
                sliderRef.current.slickGoTo(0)
              }, 2000)
            }
          },
        },
      },
    ],
  }

  return (
    <Section padding="0" border>
      <Row align="stretch">
        <Item
          flex="0 0 40%"
          color="greyLighter"
          padding="110px 65px"
          height="550px"
        >
          <TitleH2 className="title1 trait">{title}</TitleH2>
          <Text>{Parser(text)}</Text>
        </Item>
        <Item
          flex="0 0 60%"
          padding="10px"
          color="white"
          className="avantages-items"
        >
          <WrapperSlider>
            <Slider ref={sliderRef} {...settings}>
              {items.map((item, index) => (
                <Avantage key={index}>
                  <Flip left delay={index * 100}>
                    <img
                      src={
                        item.avantage.icone_avantage.localFile.childImageSharp
                          .fluid.src
                      }
                      alt="avantage"
                    />
                  </Flip>
                  <Title className="title3" margin="0">
                    {item.avantage.titre_avantage}
                  </Title>
                  <TextAvantage>{item.avantage.texte_avantage}</TextAvantage>
                </Avantage>
              ))}
            </Slider>
          </WrapperSlider>
        </Item>
      </Row>
    </Section>
  )
}

export default Avantages
