import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import RecrutementChiffres from "../components/Recrutement"
import Avantages from "../components/Recrutement/avantages"
import Offres from "../components/Recrutement/offres"
import Candidature from "../components/Recrutement/candidature"
import BannerV2 from "../components/elements/BannersV2/BannerV2"

const anchors = [
  {
    content: "Nos offres d'emplois",
    anchor: "offres",
  },
  {
    content: "Candidature spontanée",
    anchor: "candidature-spontanee",
  },
]

const bannerLeftCardContent = {
  mainContent: `SLAP digital est constamment à la recherche de nouveaux collaborateurs motivés et
    dynamiques pour rejoindre notre équipe d'experts. Et si c'était vous ?`,
  titleFirstLine: "Rejoignez la team",
  titleSecondLine: "SLAP digital",
  subTitle: "Nos offres d'emplois",
}

const Recrutement = ({ data, location }) => {
  const { recrutement, jobs } = data
  return (
    <Layout
      location={location.pathname}
      crumbLabel="Recrutement"
      locationGTM={location.href}
    >
      <SEO
        title={recrutement.acf.title}
        description={recrutement.acf.meta_description}
        lang="fr"
        url={location.pathname}
      />
      <BannerV2
        anchors={anchors}
        hasBreadCrumb
        isRecrutementPage
        bannerLeftCardContent={bannerLeftCardContent}
      />
      <RecrutementChiffres
        image={recrutement.acf.image_chiffres.localFile.childImageSharp.fluid}
        items={recrutement.acf.chiffres}
      />
      <Avantages
        title={recrutement.acf.titre_avantage}
        text={recrutement.acf.texte_avantage}
        items={recrutement.acf.avantanges}
      />
      <Offres title={recrutement.acf.titre_nos_offres_} items={jobs.edges} />
      <Candidature
        title={recrutement.acf.titre_candidature}
        text={recrutement.acf.texte_candidature}
      />
    </Layout>
  )
}

export default Recrutement

export const query = graphql`
  query {
    recrutement: wordpressPage(
      id: { eq: "bea2f876-6b5c-558d-8d4e-617505bf9811" }
    ) {
      acf {
        title
        meta_description
        titre_banniere
        texte_banniere
        hashtag
        image_banniere_new {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 3000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        chiffres {
          bloc {
            chiffre
            texte
          }
        }
        image_chiffres {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        titre_avantage
        texte_avantage
        avantanges {
          avantage {
            icone_avantage {
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
            texte_avantage
            titre_avantage
          }
        }
        titre_nos_offres_
        titre_candidature
        texte_candidature
      }
    }
    jobs: allWordpressWpFichesDePoste {
      edges {
        node {
          path
          acf {
            contrat_banniere
            titre_banniere
            image_banniere {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            statut_de_publication
          }
        }
      }
    }
  }
`
